<template>
  <v-card flat class="rounded-lg pa-2">
    <v-card-title class="text-color-title body-1 font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="text-color-main caption font-weight-bold">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text class="text-color-main mt-5">
      <div class="font-weight-bold mb-5">
        What would you like to say?
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="form.subject"
          :rules="MIXINS_REQUIRED_RULES"
          label="Subject"
          dense
          filled
          class="rounded-lg"
        />
        <v-textarea
          v-model="form.description"
          :rules="MIXINS_REQUIRED_RULES"
          label="Description"
          dense
          filled
          rows="5"
          auto-grow
          class="rounded-lg"
        />
        <div class="mt-4 d-flex">
          <div>
            <v-menu
              v-model="announcedDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.announcedDate"
                  label="Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  filled
                  class="rounded-lg mr-2"
                ></v-text-field>
                <!-- :rules="MIXINS_REQUIRED_RULES" -->
              </template>
              <v-date-picker v-model="form.announcedDate"></v-date-picker>
            </v-menu>
          </div>
          <div>
            <v-menu
              ref="endTimePickerMenu"
              v-model="announcedTimePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.announcedTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.announcedTime"
                  label="Time"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  filled
                  class="rounded-lg"
                ></v-text-field>
                <!-- :rules="MIXINS_REQUIRED_RULES" -->
              </template>
              <v-time-picker
                v-if="announcedTimePicker"
                v-model="form.announcedTime"
                full-width
                @click:minute="$refs.endTimePickerMenu.save(form.announcedTime)"
              ></v-time-picker>
            </v-menu>
          </div>
        </div>
        <!--        <div>-->
        <!--          <span class="font-weight-bold">-->
        <!--            When would you like to publish this?-->
        <!--          </span>-->
        <!--          <div class="mt-4">-->
        <!--            <v-date-picker-->
        <!--              v-model="form.date"-->
        <!--              full-width-->
        <!--              class="date-picker-no-header"-->
        <!--            ></v-date-picker>-->
        <!--          </div>-->
        <!--          <div class="mt-4">-->
        <!--            <repeat-schedule-picker-->
        <!--              @repeatScheduleData="bindRepeatScheduleData($event)"-->
        <!--            ></repeat-schedule-picker>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        -->
      </v-form>
    </v-card-text>
    <v-card-actions class="px-4">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="bg-secondary text-color-white"
        @click="gcfSubmit"
        :loading="loading"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import axios from "axios";
const announcementRepository = RepositoryFactory.get("announcement");

import { setToUtc } from "@/services/helpers/date";

export default {
  props: {
    recipient: {
      type: Object
    }
  },
  data: () => ({
    valid: false,
    form: {
      date: new Date().toISOString().substr(0, 10)
    },
    title: "Write your message",
    subtitle: "The best campaigns use short and direct messaging.",
    loading: false,
    announcedDateMenu: false,
    announcedTimePicker: false
  }),

  computed: {
    isUsersValid() {
      const { type, data } = this.recipient;
      if (type === "all") return true;
      return type === "users" && data.length > 0;
    }
  },

  methods: {
    bindRepeatScheduleData(value) {
      this.form = { ...value };
    },

    submit() {
      // Comment this for GCF Testing
      let validate = this.$refs.form.validate();
      if (validate && this.isUsersValid) {
        let formData = new FormData();
        formData.append("title", this.form.subject);
        formData.append("body", this.form.description);
        formData.append("type", this.recipient.type || "all");
        if (this.form.announcedDate && this.form.announcedTime) {
          formData.append(
            "announce_at",
            setToUtc(
              `${this.form.announcedDate} ${this.form.announcedTime}`
            ).format("YYYY-MM-DD HH:mm:ss")
          );
        }

        if (this.recipient.type === "users") {
          for (let x of this.recipient.data) {
            formData.append("user_ids[]", x);
          }
        }
        try {
          this.loading = true;
          announcementRepository.create(formData).then(response => {
            let {
              data: { responseData: data }
            } = response;
            this.$emit("submitted");
            console.log(data);
            this.loading = false;
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.MIXINS_SHOW_SNACKBAR("success", "Announcement created.");
          });
        } catch (_) {
          this.loading = false;
          this.MIXINS_SHOW_SNACKBAR(
            "error",
            "Something went wrong from the server."
          );
        }
      }
    },

    async gcfSubmit() {
      this.loading = true;
      try {
        const email = "gcfsuperadmin@gmail.com";
        const password = "!gcfsuperadmin123";
        let payload = Object.assign({}, { email, password });
        const url = "https://api-dev.gcf.org.ph";
        this.loading = true;
        const { data } = await axios.post(`${url}/api/login`, payload);
        const { accessToken } = data.data;
        console.log(accessToken);
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
        payload = Object.assign(
          {},
          {
            session_id: null,
            prayer:
              this.form.description ||
              "Lord Jesus, you are the master of life and death. Everything I have is yours, and I love you very deeply. Just one touch from you restores the sick, heals the broken, and transforms the darkness. Only you can do this Only you 2",
            is_anonymous: true
          }
        );
        await axios.post(`${url}/api/prayer-requests`, payload, config);
        this.loading = false;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.MIXINS_SHOW_SNACKBAR("success", "Announcement created.");
      } catch (_) {
        this.loading = false;
        this.MIXINS_SHOW_SNACKBAR(
          "error",
          "Something went wrong from the server."
        );
      }
    }
  }
};
</script>
